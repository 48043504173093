import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const events = [
    {
      title: 'Meeting with Team',
      start: new Date(2024, 7, 5, 10, 0), // August 5, 2024, 10:00 AM
      end: new Date(2024, 7, 5, 11, 0),   // August 5, 2024, 11:00 AM
    },
    {
      title: 'Lunch Break',
      start: new Date(2024, 7, 5, 12, 0), // August 5, 2024, 12:00 PM
      end: new Date(2024, 7, 5, 13, 0),   // August 5, 2024, 1:00 PM
    },
    // Add more events as needed
];

function MainCalendar() {
  return (
    <div className="main-calendar">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '80vh', width: '100%' }}
      />
    </div>
  );
}

export default MainCalendar;