import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Organizations from './organizations/Organizations';
import reportWebVitals from './reportWebVitals';
import MyCalendars from './mycalendars/MyCalendars';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/mycalendars" element={<MyCalendars />} />
        <Route path="/organizations" element={<Organizations />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();