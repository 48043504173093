import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <Link to="/">
              <img src={'./images/RECSTextTransparent.png'} alt="This is the RECS Logo" height="50" />
            </Link>
          </li>
          <li><Link to="/mycalendars">My Calendars</Link></li>
          <li><Link to="/">Explore</Link></li>
          <li><Link to="/organizations">Organizations</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;