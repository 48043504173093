import React from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import EventBar from './components/EventBar';
import MainCalendar from './components/MainCalendar';
import Footer from './components/Footer';
import './style.css';

function App() {
  return (
    <div>
      <header>
        <Navbar />
      </header>
      <div className="container">
        <div className="left-pane">
          <Sidebar />
          <a href = "https://forms.gle/UeFDLXjGua88Qdph8" style={{ textDecoration: 'none' }}>
            <EventBar />
          </a>
        </div>
        <MainCalendar />
      </div>
      <Footer />
    </div>
  );
}

export default App;