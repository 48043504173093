import React, { useState, useEffect } from 'react';

function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleDropdownClick = () => {
    setIsExpanded(true); // Always expand when the dropdown is clicked
  };

  const handleOptionSelect = (event) => {
    setIsExpanded(false); // Collapse when an option is selected
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown container to collapse the sidebar
    if (!event.target.closest('.dropdown-container') && !event.target.closest('.sidebar')) {
      setIsExpanded(false);
    }
  };

  // Add event listener to detect clicks outside the dropdown
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Determine the height of the sidebar based on the isExpanded state
  let sidebarHeight = isExpanded ? '130px' : '110px';

  return (
    <div className="sidebar" style={{ height: sidebarHeight }}>
      <h4 className="bartext">Calendar Type</h4>
      <form>
        <div className="dropdown-container">
          <select
            className="calendartype"
            name="calendartype"
            onClick={handleDropdownClick} // Trigger the expansion when clicked
            onChange={handleOptionSelect} // Collapse when an option is selected
          >
            <option value="Calendar">Calendar</option>
            <option value="List">List</option>
          </select>
        </div>
      </form>
    </div>
  );
}

export default Sidebar;