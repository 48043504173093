import React, { useState, useEffect } from 'react';

function Organization({ event, savedEvents, setSavedEvents }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSaved, setIsSaved] = useState(savedEvents.has(event.id));

  const defaultIcon = '/images/organization-icons/default.png';
  const defaultBanner = '/images/banners/default.png';

  // Toggle expand/collapse for the event details
  const toggleExpand = () => {
    setIsExpanded(prevState => !prevState);
  };

  // Handle click event for saving
  const handleSaveClick = (e) => {
    e.stopPropagation(); // Prevent dropdown toggle when saving
    setSavedEvents(prevSavedEvents => {
      const updatedSavedEvents = new Set(prevSavedEvents);
      if (updatedSavedEvents.has(event.id)) {
        updatedSavedEvents.delete(event.id);
      } else {
        updatedSavedEvents.add(event.id);
      }
      return updatedSavedEvents;
    });
  };

  useEffect(() => {
    // Update saved state when savedEvents changes
    setIsSaved(savedEvents.has(event.id));
  }, [savedEvents, event.id]);

  return (
    <section className="event-day">
      <div className="events-card">
        <div
          className={`event-container ${isExpanded ? 'expanded' : ''}`}
          onClick={toggleExpand} // Toggle expand on click
        >
          <div className="event">
            <div className="event-logo">
              <img src={event.imgSrc || defaultIcon} alt={`${event.title} logo`} />
            </div>
            <h3>{event.title}</h3>
            <div className="client-button-panel">
              <img
                src={isSaved ? "/images/save-filled-icon.png" : "/images/save-icon.png"}
                className={`save-icon ${isSaved ? 'saved' : ''}`}
                alt="Save Icon"
                onClick={handleSaveClick} // Handle save click
                data-id={event.id}
              />
              <a href="https://linktr.ee/rpiskiclub" target="_blank" rel="noopener noreferrer">
                <img src="/images/external-icon.png" className="external-icon" alt="External Icon" />
              </a>
            </div>
            <img
              className="event-banner-background"
              src={event.bannerSrc || defaultBanner}
              alt={`${event.title} banner`}
            />
            <div className="background-gradient"></div>
            <img
              className="dropdown-logo"
              src="/images/dropdown.png"
              alt="Dropdown"
              style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </div>
          <div
            className="event-details"
            style={{ display: isExpanded ? 'block' : 'none' }}
          >
            <div className="desc">
              <img className="location-icon" src="/images/info-icon.png" alt="Info Icon" />
              <p className="location">Official Club</p>
              <p className="description">Everyone is welcome! We're the best club on campus!</p>
              <div className="calendar-placeholder"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Organization;