import React, { useState, useEffect } from 'react';
import '../style.css'; 
import './Organizations.css';
import Navbar from '../components/Navbar';
import Organization from '../components/Organization';
import Footer from '../components/Footer';

function App() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [expandedEventId, setExpandedEventId] = useState(null);
  const [savedEvents, setSavedEvents] = useState(new Set());
  const [filter, setFilter] = useState('clubs');
  const [searchTerm, setSearchTerm] = useState('');

  // Declare organizations here
  const organizations = [
    {
      id: 1,
      imgSrc: './images/organization-icons/2947563947.png',
      title: 'Ski and Snowboard Club',
      bannerSrc: './images/banners/2947563947.png',
      type: 'clubs'
    },
    {
      id: 2,
      imgSrc: './images/organization-icons/1037583628.png',
      title: 'Rensselaer Polytechnic Institute',
      bannerSrc: './images/banners/1037583628.png',
      type: 'teams'
    },
    {
      id: 3,
      imgSrc: './images/organization-icons/9385728394.png',
      title: 'Engineers Without Borders',
      bannerSrc: './images/banners/9385728394.png',
      type: 'clubs'
    },
    {
      id: 4,
      imgSrc: './images/organization-icons/3847485627.jpg',
      title: 'Outing Club',
      bannerSrc: './images/banners/default.png',
      type: 'clubs'
    }
  ];

  const toggleDropdown = () => {
    setDropdownVisible(prevState => !prevState);
  };

  const handleEventClick = (eventId) => {
    setExpandedEventId(expandedEventId === eventId ? null : eventId);
  };

  const handleSaveClick = (eventId) => {
    setSavedEvents(prevSavedEvents => {
      const updatedSavedEvents = new Set(prevSavedEvents);
      updatedSavedEvents.has(eventId) ? updatedSavedEvents.delete(eventId) : updatedSavedEvents.add(eventId);
      return updatedSavedEvents;
    });
  };

  useEffect(() => {
    const events = document.querySelectorAll('.event');
    
    events.forEach(event => {
      const eventId = event.getAttribute('data-id');
      const eventDetails = event.querySelector('.event-details');
      const dropDownLogo = event.querySelector('.dropdown-logo');
      
      if (eventDetails && dropDownLogo) {
        if (expandedEventId === eventId) {
          eventDetails.style.display = 'block';
          event.style.height = 'auto'; // Adjust height to fit content
          dropDownLogo.style.transform = "rotate(180deg)";
          event.classList.add('expanded'); // Add a class for additional styling if needed
        } else {
          eventDetails.style.display = 'none';
          event.style.height = '100px'; // Default height
          dropDownLogo.style.transform = "rotate(0deg)";
          event.classList.remove('expanded');
        }
      }
    });
  
    const saveIcons = document.querySelectorAll('.save-icon');
    saveIcons.forEach(icon => {
      const eventId = icon.getAttribute('data-id');
      if (eventId) {
        icon.src = savedEvents.has(eventId) ? "./images/save-filled-icon.png" : "./images/save-icon.png";
      }
    });
  }, [expandedEventId, savedEvents]);
  

  const filteredOrganizations = organizations.filter(org => 
    org.type === filter && org.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const organizationsContainerStyle = {
    marginBottom: filteredOrganizations.length < 2 ? '300px' : '20px'
  };

  return (
    <div>
      <Navbar />
      <main>
        <div className="search-filter-container">
          <input 
            type="text" 
            id="search-bar" 
            placeholder="Search.." 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
          />
          <div className="filter-options">
            <span>Filter:</span>
            {[
              { value: 'clubs', label: 'Clubs' },
              { value: 'teams', label: 'Teams' },
              { value: 'greeklife', label: 'Greek Life' },
              { value: 'offcampus', label: 'Off Campus' }
            ].map((option, index) => (
              <div className="radio-item" key={index}>
                <input 
                  type="radio" 
                  name="filter" 
                  value={option.value} 
                  id={`filter-${index}`} 
                  checked={filter === option.value} 
                  onChange={() => setFilter(option.value)} 
                />
                <label htmlFor={`filter-${index}`}>{option.label}</label>
              </div>
            ))}
          </div>
        </div>

        <section className="organizations-container" style={organizationsContainerStyle}>
          {filteredOrganizations.map((org) => (
            <Organization
              key={org.id}
              event={org}
              savedEvents={savedEvents}
              setSavedEvents={setSavedEvents}
            />
          ))}
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default App;
