import React from 'react';

function Footer() {
  return (
    <footer>
      <img src= {"./images/footer-background.png"} alt="Footer Background"/>
      <div className="bottom"></div>
      <p>© 2024 - Rensselaer Event Calendar Scheduler (RECS)</p>
    </footer>
  );
}

export default Footer;