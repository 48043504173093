// MyCalendars.js
import React from 'react';

const App = () => {
  console.log("MyCalendars Component loaded successfully");
  return (
    <div>
      {/* Component Content */}
      <h1>My Calendars</h1>
    </div>
  );
};

export default App;
